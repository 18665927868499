import LinkWithImage from "@/components/atoms/LinkWithImage";
import defaultLogo from "@/public/images/navbar/Logo.svg";
import NavItems from "@/components/organisms/NavItems";
import checkin from "@/public/images/navbar/check-in.svg";
import login from "@/public/images/navbar/log-in.svg";
import phone from "@/public/images/navbar/phone-b.svg";
import medal from "@/public/images/icons/medal.svg";
import arrowleft from "@/public/images/navbar/arrow-left.svg";
import hamburguer from "@/public/images/navbar/hamburguer.svg";
import close from "@/public/images/navbar/close.svg";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import "animate.css";
const arrow = { ...arrowleft, width: 5, height: 5 };
const NavBar = ({
  logo = defaultLogo,
  className = "lg:bg-transparent",
  slug = "",
  classes = {}
}) => {
  const { t } = useTranslation("common", "routes");
  const { pathname } = useRouter();
  const [isSticky, setIsSticky] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [open, setOpen] = useState(false);
  let pathLang = pathname;
  pathLang = t(
    `routes:${t("common:header.locale")}.${pathLang.replace("/[slug]", "")}`
  );

  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
      document.body.classList.add("lg:overflow-auto");
    } else {
      document.body.classList.remove("overflow-hidden");
      document.body.classList.remove("lg:overflow-auto");
    }
  }, [open]);
  useEffect(() => {
    let y = window.scrollY;

    document.addEventListener("scroll", () => {
      if (window.scrollY < 170) {
        setIsSticky(false);
        setIsHidden(false);
      } else {
        if (y < window.scrollY) {
          y = window.scrollY;
          setIsSticky(false);
          setIsHidden(true);
        } else {
          y = window.scrollY;
          setIsSticky(true);
          setIsHidden(false);
        }
      }
    });
  });
  const topItems = [
    {
      label: t("common:header.call"),
      text: t("common:header.num"),
      href: t("common:header.num_href"),
      className:
        " text-sm text-black animate__animated animate__heartBeat animate-delay-3s",
      icon: { ...phone, className: "order-first" },
      hover: "blue",
      gtmId: "home-callnow",
    },
    {
      text: t("common:header.blog"),
      href: t("routes:/blogs"),
      className: " text-xs",
      gtmId: "Home-blog"
    },
    {
      text: t("common:header.contact"),
      href: t("routes:/contact"),
      className: " text-xs",
      gtmId: "Home-contact"
    },
    // {
    //   text: t("common:header.rewards"),
    //   href:
    //     t("common:locale") === "es"
    //       ? "https://reservas.goldenparnassusresortspa.com/bookcore/partempresas/login-particular/"
    //       : `https://booking.goldenparnassusresortspa.com/bookcore/partempresas/alta-particulares/`,
    //   target: "_blank",
    //   className: " text-xs",
    //   gtmId: "rewards",
    //   icon: medal,
    // },
    // {
    //   text: t("common:header.travelA"),
    //   href:
    //     t("common:locale") === "es"
    //       ? "https://reservas.goldenparnassusresortspa.com/bookcore/agency/login/"
    //       : `https://booking.goldenparnassusresortspa.com/bookcore/agency/login/`,
    //   target: "_blank",
    //   className: "text-xs",
    //   gtmId: "home-travel-agency" 
    // },
    {
      text: t("common:header.preCheck"),
      href: "https://precheckin-online.goldencancun.com/public/",
      target: "_blank",
      className: "text-xs",
      icon: checkin,
    },
    // {
    //   text: t("common:header.access"),
    //   href: "#",
    //   className: " text-xs   ",
    //   icon: login,
    //   items: [
    //     {
    //       text: t("common:header.user"),

    //       href:
    //         t("common:locale") === "es"
    //           ? "https://reservas.goldenparnassusresortspa.com/bookcore/partempresas/login-particular/"
    //           : `https://booking.goldenparnassusresortspa.com/bookcore/partempresas/alta-particulares/`,
    //       target: "_blank",
    //     },
    //     {
    //       text: t("common:header.company"),
    //       href:
    //         t("common:locale") === "es"
    //           ? "https://reservas.goldenparnassusresortspa.com/bookcore/partempresas/login-empresa/"
    //           : `https://booking.goldenparnassusresortspa.com/bookcore/partempresas/login-empresa/`,
    //       target: "_blank",
    //     },
    //     {
    //       text: t("common:header.agency"),
    //       href:
    //         t("common:locale") === "es"
    //           ? "https://reservas.goldenparnassusresortspa.com/bookcore/agency/login/"
    //           : `https://booking.goldenparnassusresortspa.com/bookcore/agency/login/`,
    //       target: "_blank",
    //     },
    //   ],
    //   gtmId: "home-access"
    // },
    {
      text: t("common:header.myBooking"),
      href: "https://golden-parnassus-dot-secure-mexico2.appspot.com/mis-reservas.html",
      className: " text-xs",
      target: "_blank",
      gtmId: "home-booking"
    },

  ];
  const roomsSubMenuOcean = t("common:header.oceanViewMenu", {
    returnObjects: true,
  });
  const roomsSubMenuLagoon = t("common:header.lagoonViewMenu", {
    returnObjects: true,
  });
  for (let i = 0; i < roomsSubMenuOcean.length; i++) {
    roomsSubMenuOcean[i].href = t("routes:/habitaciones" || "") + "/" + roomsSubMenuOcean[i].href;
  }
  for (let i = 0; i < roomsSubMenuLagoon.length; i++) {
    roomsSubMenuLagoon[i].href = t("routes:/habitaciones" || "") + "/" + roomsSubMenuLagoon[i].href;
  }
  const restaurantsSubMenu = t("common:tabsRestaurant", {
    returnObjects: true,
  });
  const barsSubMenu = t("common:tabsBars", { returnObjects: true });
  for (let i = 0; i < restaurantsSubMenu.length; i++) {
    restaurantsSubMenu[i].text = restaurantsSubMenu[i].tab;
    restaurantsSubMenu[i].href = t("routes:/restaurantes-bares" || "") + "/" + restaurantsSubMenu[i].slug;
  }
  for (let i = 0; i < barsSubMenu.length; i++) {
    barsSubMenu[i].text = barsSubMenu[i].tab;
    barsSubMenu[i].href = t("routes:/restaurantes-bares" || "") + "/" + barsSubMenu[i].slug;
  }
  
  const bottomItems = [
    {
      text: t("common:header.rooms"),
      href: t("routes:/rooms"),
       items: [
        {
          text: t("common:header.lagoonView"),
          href: "#",
          icon: arrow,
          items: roomsSubMenuLagoon,
        },
        {
          text: t("common:header.oceanView"),
          href: "#",
          icon: arrow,
          items: roomsSubMenuOcean,
        },
      ], 
    },
    { 
      text: t("common:header.promotions"),
      href: t("routes:/deals"),
      gtmId: "Home-promotions"
    },
    /* { text: t("common:header.hotel"), href: "/hotel" }, */
    { 
      text: t("common:header.hotel"),
      href: t("routes:/resort"),
      gtmId: "Home-hotel"
    },
    { text: t("common:header.spa"), href: t("routes:/spa") },
    {
      text: t("common:header.restaurant"),
      href: t("routes:/restaurantes-bares"),
       items: [
        {
          text: t("common:header.restaurant") + "s",
          href: "#",
          icon: arrow,
          items: restaurantsSubMenu,
        },
        {
          text: t("common:header.bars"),
          href: "#",
          icon: arrow,
          items: barsSubMenu,
        },
      ], 
    },
    { text: t("common:header.activities"), href: t("routes:/activities") },
    { 
      text: t("common:header.cancun"),
      href: t("routes:/destination"),
      gtmId: "Home-destination"
    }, 
    /*     { text: t("common:header.cancun"), href: "/cancun" },
     */ 
    {
      text: t("common:header.weddingsGroups"),
      href: t("routes:/weddings-groups"),
      gtmId: "Home-weddings",
       items: [
        { text: t("common:header.weddings"), href: t("routes:/weddings"),},
        { text: t("common:header.groups"), href: t("routes:/groups"), },
      ], 
    },
    { 
      text: t("common:header.gallery"),
      href: t("routes:/gallery"),
      gtmId: "Home-galleries"
    },
    {
      text: t("common:header.rewards"),
      href: "#",
      className: "text-sm lg:hidden",
      gtmId: "home-parnassus",
      icon: medal,
    },
    {
      text: t("common:header.travelA"),
      //href: "https://www.goldenparnassusresortspa.com/bookcore/agency/login/",
      href: `https://${t(
        "common:locale"
      )}.goldenparnassusresortspa.com/bookcore/agency/login/`,
      className: "text-sm lg:hidden",
      gtmId: "home-travel-agency-mobile" 
    },
    {
      text: t("common:header.access"),
      href: `https://${t(
        "common:locale"
      )}.goldenparnassusresortspa.com/bookcore/agency/login/`,
      className: "text-sm lg:hidden",
      icon: { ...login, height: 12, width: 12 },
      gtmId: "home-access-mobile"
    },
    {
      text: t("common:header.locale"),
      href: slug
        ? { pathname: `${pathLang}/[slug]`, query: { slug: slug } }
        : { pathname: pathLang },
      locale: t("common:header.locale"),
      className: classes?.locale || "lang text-sm flex gap-1 text-main-orange font-semibold",

      /* items: [
        {
          text: t("common:header.locale2"),
          href: "#",
        },
      ], */
    },
  ];

  return (
    <section
      className={classNames(
        { "h-full bg-main-blue": open },
        " lg:h-auto fixed z-50 w-full  ",
        { hidden: isHidden && !open },

        `${!isSticky && className}`,
        {
          "navbar-blue": isSticky,
        }
      )}
    >
      <div className="bg-main-orange">
        {(!open && (
          <NavItems
            items={[topItems[0]]}
            className={classNames("nav-top-mobile py-2 ")}
          />
        )) ||
          ""}
      </div>
      <div className="base-container base-grid items-center py-4 h-full lg:gap-x-8 relative">
        <div
          className={classNames(" col-span-12 lg:col-span-2 lg:row-span-2  ", {
            relative: !open,
          })}
        >
          <button
            className={classNames(
              "lg:hidden absolute",
              { " bottom-4": !open },
              { "top-6 right-6 z-40": open }
            )}
            onClick={() => setOpen(!open)}
          >
            <Image src={open ? close : hamburguer} alt="hamburguer" />
          </button>
          <LinkWithImage
            image={{...logo, priority: true}}
            id="Home"
            className={classNames(
              { hidden: open },
              { block: !open },
              "lg:block mx-auto lg:ml-0 w-fit"
            )}
          />
        </div>
        <div
          className={classNames(
            { hidden: open },
            "lg:block row-start-1 col-span-12 lg:col-start-3 lg:col-span-10 nav-top"
          )}
        >
          <NavItems items={topItems?.slice(1, 8)} />
        </div>
        <div
          className={classNames(
            { hidden: !open },
            {
              "lg:bg-transparent absolute p-12 top-0 bottom-0 left-0 right-0 lg:relative lg:p-0":
                open,
            },
            "lg:block col-span-12 lg:col-span-10 lg:col-end-13   lg:border-t lg:pt-4 border-main-blue-king nav-content"
          )}
        >
          <NavItems items={bottomItems} open={open} />
        </div>
        {(open && (
          <div className="block col-span-12 lg:hidden absolute bottom-0 right-0 left-0 px-6 z-40">
            <NavItems
              className=" flex justify-around border-t border-main-blue-king pt-4 pb-16"
              items={[
                {
                  text: t("common:header.preCheck"),
                  href: "https://precheckin-online.goldencancun.com/public/",
                  icon: checkin,
                },
                {
                  text: t("common:header.myBooking"),
                  //href: "https://www.goldenparnassusresortspa.com/bookcore/my-booking/",
                  href: `https://${t(
                    "common:locale"
                  )}.goldenparnassusresortspa.com/bookcore/my-booking/`,
                  gtmId: "home-booking"
                },
              ]}
            />
          </div>
        )) ||
          ""}
      </div>
    </section>
  );
};
export default NavBar;
