import React from "react";
import Link from "next/link";
import Image from "next/image";
import classNames from "classnames";

const LinkWithImage = (props) => {
  const { href = "/", className, target = "", image = {}, id = "" } = props;
  return (
    <Link href={href} id={id} className={classNames(className)}>
      <>
        {image?.src ? (
          <Image
            src={image.src}
            width={image.width}
            height={image.height}
            alt={image?.alt || "image"}
            priority={image?.priority || false}
          />
        ) : (
          ""
        )}
      </>
    </Link>
  );
};
export default LinkWithImage;
