import classNames from "classnames";
import React from "react";
import NavItem from "@/components/molecules/NavItem";

const DEFAULT_CLASSES =
  "nav-items  grid grid-cols-1 h-[85%] w-full lg:h-auto lg:flex lg:flex-wrap gap-5 items-center justify-center lg:justify-end";
const NavItems = (props) => {
  const { items = [], className = DEFAULT_CLASSES, open } = props;
  return (
    <ul className={classNames(className)}>
      {items.map((item, key) => {
        return (
          <React.Fragment key={key}>
            <NavItem {...item} open={open} />
          </React.Fragment>
        );
      })}
    </ul>
  );
};
export default NavItems;
