import Image from "next/image";
import Text from "../atoms/Text";

import logo from "@/images/icons/logo.svg";
import facebook from "@/images/icons/facebook.svg";
import instagram from "@/images/icons/instagram.svg";
import tripadvisor from "@/images/icons/tripadvisor.svg";
import Link from "next/link";
import { useTranslation } from "next-i18next";

const Footer = () => {
  const { t } = useTranslation("common");
  return (
    <footer className="">
      <div className="w-full max-w-4xl mx-auto">
        <Image
          src={logo}
          width={215}
          height={105}
          alt="logo"
          className=" mx-auto  py-3"
        />
        <p className="font-subtitle text-main-grey text-sm text-center py-3">
          <span className="block md:inline">
            Retorno San Miguelito Mza. 53 Lote 37,
          </span>{" "}
          Segunda Sección Hotelera C.P. 77500 México
        </p>
        <div className="text-center md:flex md:justify-center md:items-center py-3">
          <Link
            href={{ pathname: "/blogs" }}
            className="block md:inline-block mb-4 md:mb-0 md:pr-4 font-subtitle text-main-grey text-base uppercase"
          >
            {t("common:footer.blog")}
          </Link>
          {/* <Link
            href={{ pathname: "/social" }}
            className="block md:inline-block mb-4 md:mb-0 md:pr-4 font-subtitle text-main-grey text-sm uppercase"
          >
            {t("common:footer.social")}
          </Link> */}
          <Link
            href={{ pathname: t("routes:/terms-conditions") }}
            className="block md:inline-block mb-4 md:mb-0 md:pr-4 font-subtitle text-main-grey text-base uppercase"
          >
            {t("common:footer.privacy")}
          </Link>
          <Link
            href={{ pathname: t("routes:/privacy-policy") }}
            className="block md:inline-block mb-4 md:mb-0 md:pr-4 font-subtitle text-main-grey text-base uppercase"
          >
            {t("common:footer.cookies")}
          </Link>
          {/* <Link
            href={{ pathname: "/sitemap" }}
            className="block md:inline-block mb-4 md:mb-0 md:pr-4 font-subtitle text-main-grey text-sm uppercase"
          >
            {t("common:footer.sitemap")}
          </Link>
           */}
           <Link
            href={{ pathname: t("routes:/faq") }}
            className="block md:inline-block mb-4 md:mb-0 md:pr-4 font-subtitle text-main-grey text-base uppercase"
          >
            {t("common:footer.FAQ")}
          </Link>
          <Link
            href={"https://www.axovia.mx/"}
            target="_blank"
            className="block md:inline-block mb-4 md:mb-0 md:pr-4 font-subtitle text-main-grey text-base uppercase"
          >
            {t("common:footer.axovia")}
          </Link>

          <div className="inline-block">
            <Link
              className="mr-4 md:mr-2"
              href={"https://www.facebook.com/GoldenParnassusCancun/"}
              target="_blank"
            >
              <Image
                src={facebook}
                alt="facebook"
                className="inline-block w-12 h-12"
              />
            </Link>
            <Link
              className="mr-4 md:mr-2"
              href={"https://www.instagram.com/goldenparnassus/"}
              target="_blank"
            >
              <Image
                src={instagram}
                alt="instagram"
                className="inline-block w-12 h-12"
              />
            </Link>
            <Link
              href={
                "https://www.tripadvisor.com.mx/Hotel_Review-g150807-d184989-Reviews-Golden_Parnassus_All_Inclusive_Resort_Spa_Cancun-Cancun_Yucatan_Peninsula.html"
              }
              target="_blank"
            >
              <Image
                src={tripadvisor}
                alt="tripadvisor"
                className="inline-block w-12 h-12"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-main-blue w-full pb-14 lg:pb-16">
        <div className="text-center xl:flex xl:justify-center py-3 flex justify-center flex-wrap">
          <div className="flex items-center justify-center">
            <Link
              href={t("common:footer.num_booking_href")}
              className="text-white font-subtitle text-xs hover:text-main-orange h-12 flex items-center"
            >
              <span className="ml-1">{t("common:footer.num_booking")}</span>
              <span className="md:inline-block font-text text-sm text-[#F8F5EF] px-4">|</span>
            </Link>
          </div>
          <span className="text-white font-subtitle h-12 flex items-center text-xs">{t("common:footer.reception")}</span>
          <Link
            href={t("common:footer.num_reception_href")}
            className="text-white font-subtitle text-xs hover:text-main-orange h-12 ml-1 flex items-center"
          >
            <span>{t("common:footer.num_reception")}</span>
          </Link>
          <Link
              href={t("common:footer.num_reception2_href")}
              className="ml-1 text-white font-subtitle text-xs hover:text-main-orange h-12 flex items-center"
            >
              <span className="ml-1">{t("common:footer.num_reception2")}</span>
              <span className="md:inline-block font-text text-sm text-[#F8F5EF] px-4">|</span>
            </Link>
          <Link
            className="text-white font-subtitle text-xs hover:text-main-orange h-12 flex items-center"
            href={"mailto:contactcenter@goldenparnassusresortspa.com"}
            title={t("common:accesible_names.footer.contact")}
          >
            <span className="font-text text-[#F8F5EF] hover:text-main-orange pr-4 h-full flex items-center">
              <span className="text-xs my-auto">{t("common:footer.mail")}</span>
            </span>
          </Link>
        </div>
        <div className="text-center xl:flex xl:justify-center pb-5">
          <Text className="text-white font-subtitle text-xs">
            {t("common:footer.copyright")}
          </Text>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
