import React from "react";
import Link from "next/link";
import classNames from "classnames";
import NavItems from "@/components/organisms/NavItems";
import Image from "next/image";
import arrow from "@/public/images/navbar/arrow.png";
import arrowBack from "@/public/images/navbar/arrown-down.svg";
const NavItem = (props) => {
  const [active, setActive] = React.useState(false);
  const {
    icon = false,
    text = "",
    label = "",
    href = "#",
    className = "",
    target = "",
    locale,
    items,
    open = false,
    hover = "orange",
    gtmId = "",
  } = props;

  React.useEffect(() => {
    setActive(false);
  }, [open]);
  return (
    <li
      className={classNames(
        (hover === "orange" && "nav-item") ||
          (hover === "blue" && "nav-item-hover-blue"),
        "tracking-[.1em]",
        { "lg:relative": items?.length },
        { active: active },
        className || "text-base"
      )}
    >
      {active &&
        items?.length &&
        ((
          <button
            onClick={() => setActive(!active)}
            className="button-back -rotate-90 absolute top-8 left-6 z-40 lg:hidden"
          >
            <Image
              src={arrowBack}
              width={18}
              height={18}
              className="icon-back mx-auto  rotate-180"
              alt="icon-back"
            />
          </button>
        ) ||
          "")}
      <div className="flex gap-x-1 justify-center">
        {icon && (
          <button onClick={() => setActive(!active)} className="button-open pr-1">
            <Image {...icon} alt="icon-menu" priority />
          </button>
        )}
        <Link
          id={gtmId}
          href={href}
          target={target}
          locale={locale}
        >
          {(label && <span className="font-semibold mr-1">{label}</span>) || ""}
          <span>{text}</span>
        </Link>
      </div>
      {!icon &&
        items?.length &&
        ((
          <button
            onClick={() => setActive(!active)}
            className="button-open  -mr-9 lg:mr-0 lg:hidden "
          >
            <Image
              src={arrowBack}
              width={12}
              className="icon-open mx-auto -rotate-90  lg:invert-[.50]"
              alt="icon-open"
            />
          </button>
        ) ||
          "")}
      {(items?.length && (
        <div className="sub-menu">
          <Image
            src={arrow}
            width={12}
            className="icon mx-auto -rotate-90 -mb-1 hidden lg:block"
            alt="icon-arrow"
          />

          <NavItems items={items} className="sub-nav-items" />
        </div>
      )) ||
        ""}
    </li>
  );
};
export default NavItem;
